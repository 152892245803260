import React from 'react';
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import Slider from "react-slick";
import Layout from '../components/layout';
import SEO from "../components/seo";
import AlohaLogoTop from '../images/AlohaLogoTop.png';
import AlohaLogoTop2x from '../images/AlohaLogoTop@2x.png';
import navLogoKvet from '../images/navLogoKvet.png';
import navLogoKvet2x from '../images/navLogoKvet@2x.png';
import { Column, Container, FullContaner, Navigation, Row } from '../styles/bootstrap';
import { MenuLink, TopLogoLink, SliderImage, ImageBox, H1Styled, Table, FooterItem, FooterTitle, FooterItemBottom } from '../styles/style';
import Img from 'gatsby-image';

import bgKvetBig from '../images/kvet_cb@2x.png';
import blurDesc from '../images/blurDescription@2x.png';

import bigText from '../images/logoAlohaTextBlack.png';
import bigText2x from '../images/logoAlohaTextBlack@2x.png';

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

import AI_mappointer from '../images/AI_mappointer.png'
import AI_mappointer2x from '../images/AI_mappointer@2x.png'

import AI_telephone from '../images/AI_telephone.png'
import AI_telephone2x from '../images/AI_telephone@2x.png'

import AI_mail from '../images/AI_mail.png'
import AI_mail2x from '../images/AI_mail@2x.png'

import CzFlag from '../images/czFlag.png'
import CzFlag2x from '../images/czFlag@2x.png'

import EnFlag from '../images/enFlag.png'
import EnFlag2x from '../images/enFlag@2x.png'

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
};


const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap defaultZoom={14} defaultCenter={{ lat: props.lat, lng: props.lng }}>
        <Marker position={{ lat: props.lat, lng: props.lng }} />
    </GoogleMap>
))


class IndexPage extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false
    }
    render() {
        const { data } = this.props;
        let _locale = data.site.siteMetadata.locale || 'cs-CZ';
        _locale = _locale.replace(/\s/g, '');
        console.log(_locale); // LOCALE
        const dataHlavni = data.allContentfulHlavni.edges.filter(x => x.node.node_locale === _locale)[0].node
        const { photoIndex, isOpen } = this.state;
        const gallery = dataHlavni.galerie;
        return (
            <Layout>
                <Helmet>
                    <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                    <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                </Helmet>
                <SEO title={_locale === 'cs-CZ' ? 'Apartmán Aloha' : 'Apartment Aloha'} keywords={[`apartman`, `aloha`, `pronajem`]} />
                <Navigation fixed={true} bgMenu={'rgba(255,255,255,0.8)'}>
                    <TopLogoLink href="/" style={{ paddingLeft: 120 }}>
                        <img srcSet={`${navLogoKvet} 1x, ${navLogoKvet2x} 2x`} alt={_locale === 'cs-CZ' ? 'Apartmán Aloha' : 'Apartment Aloha'} style={{ zIndex: 1, position: 'absolute', top: 0, left: 0 }} />
                        <img srcSet={`${AlohaLogoTop} 1x, ${AlohaLogoTop2x} 2x`} alt={_locale === 'cs-CZ' ? 'Apartmán Aloha' : 'Apartment Aloha'} />
                    </TopLogoLink>
                    <div>
                        <ul>
                            <MenuLink>
                                <a href="/">{_locale === 'cs-CZ' ? 'Domů' : 'Home'}</a>
                            </MenuLink>
                            <MenuLink>
                                <a href="#apartman">{_locale === 'cs-CZ' ? 'Apartmán' : 'Apartment'}</a>
                            </MenuLink>
                            <MenuLink>
                                <a href="#galerie">{_locale === 'cs-CZ' ? 'Galerie' : 'Gallery'}</a>
                            </MenuLink>
                            <MenuLink>
                                <a href="#apartman">{_locale === 'cs-CZ' ? 'Nabídka' : 'Offer'}</a>
                            </MenuLink>
                            <MenuLink>
                                <a href="#kontakt">{_locale === 'cs-CZ' ? 'Kontakt' : 'Contact'}</a>
                            </MenuLink>
                            <MenuLink>
                                <a href="https://apartment-aloha.cz/">
                                    <img srcSet={`${CzFlag} 1x, ${CzFlag2x} 2x`} alt="CZ" />
                                </a>
                            </MenuLink>
                            <MenuLink>
                                <a href="https://apartment-aloha.com/">
                                    <img srcSet={`${EnFlag} 1x, ${EnFlag2x} 2x`} alt="EN" />
                                </a>
                            </MenuLink>
                        </ul>
                    </div>
                </Navigation>
                <FullContaner>
                    <Slider {...settings}>
                        {dataHlavni.slider.map((image, i) => <SliderImage key={i}>
                            <div style={{ backgroundImage: 'url(\'' + image.file.url + '\')' }} />
                        </SliderImage>)}
                    </Slider>
                </FullContaner>
                <Container full id="apartman">
                    <Container>
                        <Row columnPadding="30px" style={{ lineHeight: '2em' }}>
                            <Column size={{ xs: 1, md: 1 / 2 }} color="white" style={{ marginTop: '-90px', zIndex: 1, backgroundImage: `url('${blurDesc}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>
                                <H1Styled>{_locale === 'cs-CZ' ? 'Apartmán' : 'Apartment'}
                                    <img srcSet={`${bigText} 1x, ${bigText2x} 2x`} alt="Apartmán Aloha" />
                                </H1Styled>
                                <div style={{ fontSize: 15, fontWeight: 'bold' }}>Znojmo</div>
                                <div style={{ marginTop: 40 }} dangerouslySetInnerHTML={{ __html: dataHlavni.popis_hlavni.childContentfulRichText.html }} />
                            </Column>
                            <Column size={{ xs: 1, md: 1 / 2 }}>
                                <h2>{_locale === 'cs-CZ' ? 'Nabídka' : 'Offer'}</h2>
                                <div>{_locale === 'cs-CZ' ? 'CENÍK PRONÁJMU APARTÁMU ALOHA V ROCE' : 'PRICE LIST FOR APARTMENT RENTAL IN'} {(new Date()).getFullYear()}</div>
                                <Table className="priceTable">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th style={{}}>{_locale === 'cs-CZ' ? 'Apartmán Aloha' : 'Apartment Aloha'}</th>
                                            <th style={{}}>{_locale === 'cs-CZ' ? '6 a více nocí' : '6 or more nights'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataHlavni.cenik.map((item, i) => <tr key={i}>
                                            <td>{item.obdobi}</td>
                                            <td>{item.cena1}</td>
                                            <td>{item.cena2}</td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                                <div dangerouslySetInnerHTML={{ __html: dataHlavni.popis_doplnujici.childContentfulRichText.html }} />
                            </Column>
                        </Row>
                    </Container>
                </Container>
                <Container id="galerie" color="#f9f9f9" full style={{ paddingTop: 40, paddingBottom: 40, backgroundImage: `url('${bgKvetBig}')`, backgroundRepeat: 'no-repeat', backgroundSize: 400, backgroundPosition: '30px 30px' }}>
                    <Container>
                        <Row>
                            {gallery.map((image, i) => <Column key={i} size={{ xs: 1, md: 1 / 2, lg: 1 / 3 }}>
                                <ImageBox onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                                    <Img fixed={image.fixed || null} fluid={image.fluid || null} />
                                </ImageBox>
                            </Column>
                            )}
                        </Row>
                    </Container>
                </Container>
                <FullContaner height={'450px'}>
                    <MyMapComponent
                        lat={48.878147}
                        lng={16.045385}
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyASX3VZ4FR8r8PGwLMEkR_AlGosf0dx0fs&v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </FullContaner>
                <Container full color="#414141" id="kontakt">
                    <Container>
                        <Row>
                            <Column style={{ textAlign: 'center' }} padding={'40px 20px 10px 20px'} size={{ xs: 1 }}>
                                <FooterTitle>{_locale === 'cs-CZ' ? 'KONTAKTUJTE NÁS' : 'CONTACT US'}</FooterTitle>
                            </Column>
                        </Row>
                        <Row>
                            <Column size={{ xs: 1, lg: 1 / 3 }} padding={'10px'}>
                                <FooterItem>
                                    <div><img srcSet={`${AI_mappointer} 1x, ${AI_mappointer2x} 2x`} alt="Mapa" /></div>
                                    <div>
                                        <div>Růžičkova ​3817/7</div>
                                        <div>669 02 Znojmo</div>
                                    </div>
                                </FooterItem>
                            </Column>
                            <Column size={{ xs: 1, lg: 1 / 3 }} padding={'10px'}>
                                <FooterItem>
                                    <div> <img srcSet={`${AI_telephone} 1x, ${AI_telephone2x} 2x`} alt="Mapa" /></div>
                                    <div>
                                        <div>Libor Ondráček</div>
                                        <div>+ 420 724 370 887</div>
                                    </div>
                                </FooterItem></Column>
                            <Column size={{ xs: 1, lg: 1 / 3 }} padding={'10px'}>
                                <FooterItem>
                                    <div><img srcSet={`${AI_mail} 1x, ${AI_mail2x} 2x`} alt="Mapa" /></div>
                                    <div>
                                        <div><a href="mailto:ondracek.libor@gmail.com">ondracek.libor@gmail.com</a></div>
                                    </div>
                                </FooterItem></Column>
                        </Row>
                        <Row style={{ borderTop: '1px solid hsla(0,0%,100%,.121569)', padding: '10px 20px 10px 20px', margin: '40px 20px 10px' }}>
                            <Column size={{ xs: 1, md: 1 / 2, lg: 1 / 2 }}><FooterItemBottom>{_locale === 'cs-CZ' ? 'Apartmán provozuje:' : 'Apartment is operated:'}<br />TEVOKA Investments, s r.o. <br />Růžičkova ​3817/7, 669 02 Znojmo</FooterItemBottom></Column>
                            <Column size={{ xs: 1, md: 1 / 2, lg: 1 / 2 }}><FooterItemBottom>IČ: 061 38 241<br />DIČ: CZ06138241</FooterItemBottom></Column>
                        </Row>
                    </Container>
                </Container>
                {isOpen && (
                    <Lightbox
                        mainSrc={gallery[photoIndex].file.url}
                        nextSrc={gallery[(photoIndex + 1) % gallery.length].file.url}
                        prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length].file.url}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + gallery.length - 1) % gallery.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % gallery.length,
                            })
                        }
                    />
                )}
            </Layout >
        )
    }
}

// 578a75cc-6f78-55b8-b841-1ce4518b7165 - en-US
// a4b69630-ee47-52c1-a3f4-2894b81ad656 - cs-CZ
export default IndexPage
export const query = graphql`
  query {
    allContentfulHlavni {
    edges {
      node {
        id
        node_locale
        popis_hlavni {
          childContentfulRichText {
            html
          }
        }
        popis_doplnujici {
          childContentfulRichText {
            html
          }
        }
        slider {
          file {
            url
          }
        }
        galerie {
          fluid(maxWidth: 550, maxHeight: 500) {
            aspectRatio
            ...GatsbyContentfulFluid_withWebp
          }
          file {
            url
          }
        }
        cenik {
          obdobi
          cena1
          cena2
        }
      }
    }
  }
  site {
          siteMetadata {
            title
            locale
          }
        }
}
`

